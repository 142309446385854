<template>
  <div class="c-apply-schedule" @click="close">
    <div :class="{'hidden': !item.scheduleTemplate?.[0]}" class="pinned">
      <div class="pinned-schedule">Прикрепленное расписание: {{ item.scheduleTemplate?.[0]?.title }}</div>
      <n-button v-if="item.scheduleTemplate?.[0]" class="apply-btn" @click.stop="unlink">Открепить от расписания</n-button>
    </div>

    <n-button class="apply-btn" @click.stop="show = true">Применить расписание</n-button>

    <div v-if="show" class="c-add-visit">
      <n-loader :loading="$var('load')" />
      <div class="form" @click.stop>
        <div class="title main">Выберите расписание</div>
        <n-input v-model="search" class="search-input" placeholder="Поиск" />
        <div class="schedule-list">
          <div v-for="schedule in filtered" :key="schedule.id" class="schedule-row" @click="selectedSchedule = schedule">
            <div class="left">
              {{ schedule.title }}
            </div>
            <div class="right">
              <div class="circle" :class="{'active': schedule.id === selectedSchedule.id}" />
            </div>
          </div>
        </div>
        <div v-if="Object.keys(selectedSchedule).length > 0 && type === 'user' && !useHiredDate" class="field check-box">
          <div class="time-selector-wrapper">
            <div class="title">
              Дата начала раписания:
            </div>
            <c-date-picker :date.sync="applyDate" />
          </div>
        </div>
        <div v-if="Object.keys(selectedSchedule).length > 0 && type === 'user'" class="field check-box" @click="useHiredDate = !useHiredDate">
          <div class="box">
            <img v-show="useHiredDate" :src="imgDone" alt="">
          </div>
          <div class="title">
            Использовать дату найма, как дату начала графика
          </div>
        </div>
        <div v-if="Object.keys(selectedSchedule).length > 0 && type === 'user'" class="field check-box" @click="forceRefactoring = !forceRefactoring">
          <div class="box">
            <img v-show="forceRefactoring" :src="imgDone" alt="">
          </div>
          <div class="title">
            Изменить существующие дни
          </div>
        </div>
        <div class="buttons">
          <n-button class="cancel" @click="close">Отмена</n-button>
          <n-button :disabled="!Object.keys(selectedSchedule).length > 0" @click="apply">Применить</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import imgDone from '../../assets/done.svg'

export default {
  name: 'CApplySchedule',
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgDone,
      show: false,
      selectedSchedule: {},
      schedules: [],
      useHiredDate: false,
      forceRefactoring: false,
      applyDate: new Date(),
      search: '',
    }
  },
  computed: {
    filtered() {
      const query = this.search.toLowerCase()
      return $n.filter(this.schedules, (schedule) =>
        schedule.title.toLowerCase().includes(query)
      )
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.load()
      }
    },
  },
  created() {
  },
  methods: {
    close() {
      this.show = false
      this.selectedSchedule = {}
      this.useHiredDate = false
      this.applyDate = new Date()
    },
    apply() {
      this.$var('load', true)
      $api.schedules.apply(this.selectedSchedule.id, { type: this.type,
        modelId: this.item.id,
        useHiredDate: this.useHiredDate,
        forceRefactoring: this.forceRefactoring,
        applyDate: new Date(this.applyDate.setHours(0, 0, 0, 0)),
      }).then(() => {
        this.$emit('done')
        this.close()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    unlink() {
      this.$var('load', true)
      $api.schedules.unlink(this.item.scheduleTemplate[0].id, { type: this.type, modelId: this.item.id, }).then(() => {
        this.$emit('done')
        this.close()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    load() {
      this.$var('load', true)
      $api.schedules.get().all().then((response) => {
        this.schedules = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-apply-schedule {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-input {
    margin-bottom: 15px;
  }
  .pinned-schedule {
    height: 40px;
    background-color: var(--primary);
    color: #ffffff;
    border-radius: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .pinned {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.hidden {
      opacity: 0  ;
    }
  }

  .field {
    flex: 1;
    display: flex;
    margin-bottom: 15px;
    .n-input {
      margin-left: 25px;
      min-width: 100px;
    }
    &.check-box {
      display: flex;
      align-items: center;
      width: 100%;
      .box {
        margin-right: 15px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #000000;
      }
      .title {
        justify-content: left;
        margin-bottom: 0;
      }
    }
  }
  .schedule-list {
    width: 100%;
    border-top: 2px solid #EBEBEB;
    border-bottom: 2px solid #EBEBEB;
    padding: 5px;
    margin-bottom: 15px;
    max-height: 400px;
    overflow-y: auto;
    .schedule-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      background-color: rgba(235, 235, 235, 0.38);
      &:last-child {
        margin-bottom: 0;
      }
      .circle {
        background-color: #EBEBEB;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &.active {
          background-color: var(--primary);
        }
      }
    }
  }

  .c-add-visit {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 10px;
    background-color: #00000080;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .n-button {
    border: none;
    border-radius: 24px;
    background-color: var(--primary) !important;
    color: #FFFFFF;
    &.cancel {
      color: #000000;
      background-color: #D4CFCF !important;
    }
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
    .time-selector-wrapper {
      width: 100%;
      margin-bottom: 15px;
      .date-picker {
        max-width: 320px;
      }
      .title {
        &.danger {
          width: 100%;
          color: red;
          text-align: center;
        }
        margin-bottom: 5px;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
      }
    }
    .time-selector {
      display: flex;
      align-items: center;
      justify-content: start;
      ::v-deep {
        .n-select {
          .n-icon {
            display: none;
          }
          .n-text {
            text-align: center;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          width: 60px;
          .n-content {
            padding: 0;
          }
          .n-icon {
            margin: 0 !important;
          }
          .n-popup-content {
            width: 60px;
            .n-dropdown-item {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .n-item {
              text-align: center;
            }
          }
          .n-wrapper {
            padding: 0 5px;
            width: 60px;
          }
          .n-dropdown {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
