<template>
  <div class="c-add-visit" @click="close">
    <n-loader :loading="$var('load')" />
    <div class="form" @click.stop>
      <div class="title main">Создание посещения</div>

      <div class="time-selector-wrapper">
        <div class="title">
          Укажите время посещения:
        </div>
        <div class="time-selector">
          <n-select name="inputdisabled" small :data="hours" v-bind="$form.input('hour', 'select')" readonly class="time-select" />
          :<n-select name="inputdisabled2" small :data="minutes" v-bind="$form.input('minute', 'select')" readonly class="time-select" />
        </div>
      </div>

      <div class="time-selector-wrapper">
        <div class="title">
          Укажите дату посещения:
        </div>
        <c-date-picker v-if="preDate === null" :date.sync="date" />
        <c-date-picker v-else :pre-date="preDate" :date.sync="date" />
      </div>

      <div class="time-selector-wrapper">
        <div class="title">
          Укажите точку контроля:
        </div>
        <n-select readonly :data="locationPoints" :value.sync="locationPoint" />
      </div>
      <div v-if="$var('danger')" class="time-selector-wrapper">
        <div class="title danger">
          {{ $var('danger') }}
        </div>
      </div>
      <div class="buttons">
        <n-button class="cancel" @click="close">Отмена</n-button>
        <n-button @click="add">Добавить</n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CAddVisit',
  props: {
    userId: {
      type: Number,
      required: true,
    },
    locationPoints: {
      type: Array,
      required: true,
    },
    preDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      companyId: $app.auth.user().companyId,
      hours: [],
      minutes: [],
      locationPoint: {},
      date: null,
    }
  },
  created() {
    if (this.preDate) {
      this.date = this.preDate
    }
    this.generateHours()
    this.$form.init({
      hour: {},
      minute: {},
    })
    this.$form.rules({
      hour: [ 'required', ],
      minute: [ 'required', ],
    })
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    generateHours() {
      this.hours = Array.from({ length: 24, }, (_, i) => ({
        value: i > 9?i:'0'+(i),
        title: i > 9?i:'0'+(i),
      }))
      this.minutes = Array.from({ length: 60, }, (_, i) => ({
        value: i > 9?i:'0'+(i),
        title: i > 9?i:'0'+(i),
      }))
    },
    add() {
      const data = {
        date: new Date(this.date.setHours(Number(this.$form.get('hour.value')), Number(this.$form.get('minute.value')), 0, 0)),
        locationPointId: this.locationPoint.id,
      }
      if (this.$form.check() && this.date && Object.keys(this.locationPoint).length > 0) {
        this.$var('load', true)
        $api.visits.create(this.companyId, this.userId, data).then(() => {
          this.close()
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        this.$var('danger', 'Есть неправильно заполненные поля')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-add-visit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #00000080;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep() {
    .n-select {
      .vs__actions {
        display: none;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .n-button {
      border: none;
      border-radius: 24px;
      background-color: var(--primary) !important;
      color: #FFFFFF;
      &.cancel {
        color: #000000;
        background-color: #D4CFCF !important;
      }
    }
  }
  .buttons {
    width: 100%;
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
    .time-selector-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .title {
        &.danger {
          width: 100%;
          color: red;
          text-align: center;
        }
        margin-right: 15px;
        font-weight: normal;
        font-size: 14px;
        color: #000000;
      }
    }
    .time-selector {
      display: flex;
      align-items: center;
      justify-content: start;
      ::v-deep {
        .n-select {
          .n-icon {
            display: none;
          }
          .n-text {
            text-align: center;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          width: 60px;
          .n-content {
            padding: 0;
          }
          .n-icon {
            margin: 0 !important;
          }
          .n-popup-content {
            width: 60px;
            .n-dropdown-item {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .n-item {
              text-align: center;
            }
          }
          .n-wrapper {
            padding: 0 5px;
            width: 60px;
          }
          .n-dropdown {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
