<template>
  <div class="c-recalc-user-sheet" @click="close">
    <n-button class="apply-btn" @click.stop="show = true">Пересчитать расписание</n-button>
    <div v-if="show" class="c-add-visit">
      <n-loader :loading="$var('load')" />
      <div class="form" @click.stop>
        <div class="title main">Пересчет расписания</div>
        <div class="field check-box">
          <div class="time-selector-wrapper">
            <div class="title">
              Даты пересчета
            </div>
            <div class="selectors">
              <c-date-picker :date.sync="applyDate" />-
              <c-date-picker :date.sync="dateTo" />
            </div>
          </div>
        </div>
        <div class="buttons">
          <n-button class="cancel" @click="close">Отмена</n-button>
          <n-button @click="apply">Применить</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import imgDone from '../../assets/done.svg'

export default {
  name: 'CRecalcUserSheet',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgDone,
      show: false,
      applyDate: new Date(),
      dateTo: new Date(),
    }
  },
  methods: {
    close() {
      this.show = false
      this.applyDate = new Date()
      this.dateTo = new Date()
    },
    apply() {
      this.$var('load', true)
      $api.users.recalcSheet($app.auth.user().companyId, this.item.id, {
        date: new Date(this.applyDate.setHours(0, 0, 0, 0)),
        dateTo: new Date(this.dateTo.setHours(0, 0, 0, 0)),
      }).then(() => {
        this.$emit('done')
        this.close()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-recalc-user-sheet {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-input {
    margin-bottom: 15px;
  }
  .pinned-schedule {
    height: 40px;
    background-color: var(--primary);
    color: #ffffff;
    border-radius: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .pinned {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.hidden {
      opacity: 0  ;
    }
  }

  .field {
    flex: 1;
    display: flex;
    margin-bottom: 15px;
    .n-input {
      margin-left: 25px;
      min-width: 100px;
    }
    &.check-box {
      display: flex;
      align-items: center;
      width: 100%;
      .box {
        margin-right: 15px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #000000;
      }
      .title {
        justify-content: left;
        margin-bottom: 0;
      }
    }
  }
  .schedule-list {
    width: 100%;
    border-top: 2px solid #EBEBEB;
    border-bottom: 2px solid #EBEBEB;
    padding: 5px;
    margin-bottom: 15px;
    max-height: 400px;
    overflow-y: auto;
    .schedule-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      background-color: rgba(235, 235, 235, 0.38);
      &:last-child {
        margin-bottom: 0;
      }
      .circle {
        background-color: #EBEBEB;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &.active {
          background-color: var(--primary);
        }
      }
    }
  }

  .c-add-visit {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 10px;
    background-color: #00000080;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .n-button {
    border: none;
    border-radius: 24px;
    background-color: var(--primary) !important;
    color: #FFFFFF;
    &.cancel {
      color: #000000;
      background-color: #D4CFCF !important;
    }
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
    .time-selector-wrapper {
      width: 100%;
      margin-bottom: 15px;

      .selectors {
        display: flex;
      }
      .date-picker {
        max-width: 320px;
      }
      .title {
        &.danger {
          width: 100%;
          color: red;
          text-align: center;
        }
        margin-bottom: 5px;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
      }
    }
    .time-selector {
      display: flex;
      align-items: center;
      justify-content: start;
      ::v-deep {
        .n-select {
          .n-icon {
            display: none;
          }
          .n-text {
            text-align: center;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          width: 60px;
          .n-content {
            padding: 0;
          }
          .n-icon {
            margin: 0 !important;
          }
          .n-popup-content {
            width: 60px;
            .n-dropdown-item {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .n-item {
              text-align: center;
            }
          }
          .n-wrapper {
            padding: 0 5px;
            width: 60px;
          }
          .n-dropdown {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
